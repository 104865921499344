
// respond is the name of your mixin

@mixin respond ($breakpoint) {

  // $breakpoint is simply a variable that can have several values

  @if $breakpoint==tablet {

    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
}


.Features {
  .timeline {

    &-item {
      justify-content: space-between;
      width: 100%;
    }

    &-control {
      position: relative;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &:before {
        content: '';
        position: absolute;
        right: 100%;
        width: calc(100% - 11px);
        height: 1px;
        background: transparent;
        border-top: 2px dashed #6A2BE9;

        @include respond(mobile) {
          display: none;
        }
      }
    }

    &-circle {
      align-self: center;
      border-radius: 50%;
      z-index: 1;
      background: #6A2BE9;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: 100%;
        z-index: 0;
        bottom: 0;
        width: 1px;
        height: 60%;
        background: transparent;
        border-left: 2px dashed #6A2BE9;

        @include respond(mobile) {
          height: 200%;

          &:last-child {
            height: 250%;
          }
        }
      }

      &__number {
        color: #FFFFFF;
        line-height: 1;
      }

      &__step {
        color: #FFFFFF;
      }
    }
  }
}

