@tailwind base;
@tailwind components;
@tailwind utilities;

.BlogItem ol {
    list-style: numeric;
    padding-left: 16px;
    margin-bottom: 40px;
    font-size: 18px;
}

.BlogItem ul {
    list-style: '-- ';
    padding-left: 16px;
    margin-bottom: 40px;
}

.BlogItem p {
    font-size: 18px;
    margin-bottom: 40px;
}

.BlogItem {
    align-content: start;
}

.BlogItem ol p {
    margin-bottom: 0;
}

.BlogItem ul p {
    margin-bottom: 0;
}

.BlogItem li {
    margin-bottom: 16px;
}

.BlogItem h5 {
    font-size: 24px;
    font-weight: 700;
    align-self: baseline;
    margin-bottom: 15px;
    display: flex;
    color: #6A2BE9;
    text-align: left;
}

.social-share p {
    margin-bottom: 0 !important;
}
